import React from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import { getQs } from '../helper/getQs';
import { useStickyState } from '../hooks/useStickyState';

function utmString(entries) {
  if(entries) {
    return '?' + Object.entries(entries).map(entry => `${entry[0]}=${entry[1]}`).join('&')
  } else {
    return '';
  }
}

function Typeform() {
const [ utm,  ] = useStickyState(getQs(), 'state.ecokraft')

return <ReactTypeformEmbed url={`https://cloudmate.typeform.com/to/YTF6zNHI/${utmString(utm)}`} />
}

export default Typeform;
