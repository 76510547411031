import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import EcoMarker from './tiny.png';
// Generate marker http://chart.googleapis.com/chart?chst=d_map_pin_letter&chld=xxx%7c60D175%7c000000&.png

import mapStyle from './mapStyle.json'
const qs = require('query-string');
const containerStyle = {
  height: '100%'
};

if(process.env.NODE_ENV === 'development') {
  containerStyle.width = '700px';
  containerStyle.height = '400px';
}

const filter = qs.parse(window.location.search)?.filter ? qs.parse(window.location.search).filter.split(',') : ''; 

function MapRender({adresser}) {
  const center = {
    lat: adresser.length > 0 ? adresser.reduce((total, next) => total + next.lat, 0) /
    adresser.length : -58.29923764285714,
    lng: adresser.length > 0 ? adresser.reduce((total, next) => total + next.lng, 0) /
    adresser.length : -15.518808814285716,
  };

  const filtered = adresser?.filter((adress) => !filter.includes(adress.city) && adress.status === 'Aktivt'); 
  
  return (
    <LoadScript
      googleMapsApiKey={'AIzaSyARtZW7ceI94Yn_7LxdZY49lXpCBbEHLGE'}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        mapContainerClassName={"office-map-size"}
        center={center}
        zoom={6}
        disableDefaultUI
        // containerElement={<div className="office-map-size" />}
        // mapElement={<div className="office-map-size" />}
        options={{
          // mapTypeId: 'satellite',
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
          draggable: false,
          styles: mapStyle,
        }}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        {filtered?.map((adress, i) => (
              <Marker
                position={{ lat: adress.lat - 0.25, lng: adress.lng }}
                // onDragEnd={onMarkerDragEnd}
                draggable={false}
                key={adress._id}
                opacity={0.5}
                icon={EcoMarker}
                anchor= {[0, 0]}
                // scaledSize={50, 50}
              />
            ))}
      </GoogleMap>
    </LoadScript>
  )
}

export default MapRender;