import React from 'react';
import { getQs } from '../helper/getQs';
import { useStickyState } from '../hooks/useStickyState';

function FormInputUtm() {
const [ utm,  ] = useStickyState(getQs(), 'state.ecokraft')
// return <input type="text" id="form-input-utm" placeholder="utm" name="utm" value={JSON.stringify(utm)}/>
return (
  <React.Fragment>
    <input type="text" id="form-input-utm_source" placeholder="utm_source" name="utm_source" value={utm.utm_source} hidden/>
    <input type="text" id="form-input-utm_medium" placeholder="utm_medium" name="utm_medium" value={utm.utm_medium} hidden/>
    <input type="text" id="form-input-utm_campaign" placeholder="utm_campaign" name="utm_campaign" value={utm.utm_campaign} hidden/>
    <input type="text" id="form-input-utm_term" placeholder="utm_term" name="utm_term" value={utm.utm_term} hidden/>
    <input type="text" id="form-input-utm_content" placeholder="utm_content" name="utm_content" value={utm.utm_content} hidden/>
  </React.Fragment>
)
}

export default FormInputUtm;
