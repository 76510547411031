import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import Utm from './components/Utm';
import Typeform from './components/Typeform';
import Referral from './components/Referral';
import reportWebVitals from './reportWebVitals';
import Map from './components/Map';
import FormInputUtm from './components/FormInputUtm';

const utmComponent = document.getElementById('utm-component');
const typeformComponent = document.getElementById('typeform-component');
const referralComponent = document.getElementById('referral');
const mapComponent = document.getElementById('map');
const formComponent = document.getElementById('form-input-utm');

if (utmComponent) {
  ReactDOM.render(<Utm />, document.getElementById('utm-component'));
}

if (typeformComponent) {
  ReactDOM.render(<Typeform />, document.getElementById('typeform-component'));
}

if (referralComponent) {
  ReactDOM.render(<Referral />, document.getElementById('referral'));
}

if (mapComponent) {
  ReactDOM.render(<Map />, document.getElementById('map'));
}

if (formComponent) {
  ReactDOM.render(<FormInputUtm />, document.getElementById('form-input-utm'));
}

reportWebVitals();
