import React from 'react';
import { getQs } from '../helper/getQs';
import { useStickyState } from '../hooks/useStickyState';

function Utm() {
 useStickyState(getQs(), 'state.ecokraft')
  
  return (
    <React.Fragment/>
  );
}

export default Utm;


