import React, { useRef, useState, useEffect } from 'react';
import { EmailShareButton, EmailIcon } from 'react-share';
import { getQs } from '../helper/getQs';

function Referral() {

  const [refid, setRefid] = useState(() => (''));
  const [copySuccess, setCopySuccess] = useState(() => (''));
  const textAreaRef = useRef(null);

  useEffect(() => {
    const searchParams = getQs();

    if (searchParams?.refid) {      
      setRefid(searchParams.refid);
    }
  }, []);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Kopierat!');
  };

  function share(title, text, url) {
    navigator.share({
      title,
      text,
      url,
    });
  };

  const subject = `Jag har ECOKRAFTEN och vill dela den med dig`;
  const message = `Hej,

Om du köper en solcellsanläggning från ECOKRAFT med min rekommendationslänk får du inte bara 2000 kr rabatt, du får även ECOKRAFTEN. Det är bara befintliga ägare av solcellsanläggningar från ECOKRAFT som kan dela den. Jag tycker att det är ett bra erbjudande så ville dela det med dig.
    
`;

    return (
      <React.Fragment>
        {refid === '' ? (
          <div className="referrallink no-code">
            <h1>Ingen kod funnen.</h1>
          </div>
        ) : (
          <div className="referral-grid" style={{ display: 'grid' }}>
            <form>
              <div className="share-txt">Unik rekommendationslänk</div>
              <div>
                <input
                  className="referrallink"
                  type="text"
                  ref={textAreaRef}
                  value={`https://ny.ecokraft.se/${refid}`}
                  onClick={copyToClipboard}
                />
                {/* Logical shortcut for only displaying the 
            button if the copy command exists */
                document.queryCommandSupported('copy') && (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={copyToClipboard}
                    onKeyDown={copyToClipboard}
                    readOnly
                  >
                    <p className="clicktocopy">{copySuccess}</p>
                  </div>
                )}
              </div>
            </form>
            <div className="share-btns">
              {navigator.share ? (
                <React.Fragment>
                  <button
                    type="button"
                    className="btn-share"
                    onClick={() =>
                      share(
                        subject,
                        message,
                        `https://ny.ecokraft.se/${refid}`
                      )
                    }
                  >
                    DELA DIN REKOMMENDATIONSLÄNK
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="share-txt center">DELA</div>
                  <EmailShareButton
                    url={`${message}  https://ny.ecokraft.se/${refid}`}
                    subject={subject}
                  >
                    <div style={{ paddingTop: '12px' }}>
                      <EmailIcon
                        size={60}
                        round
                        bgStyle={{ fill: '#aad15e' }}
                      />
                    </div>
                  </EmailShareButton>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  
}

export default Referral;
