import React, { useState, useEffect } from 'react';
import MapRender from './MapRender';


function Map() {
  const [adresser, setAdresser] = useState([])

  useEffect(() => {
    fetch('https://cm.subflow.se/api/kontor/adresser')
    .then(res => res.json())
    .then(res => setAdresser(res))
    .catch(err => {
      console.log(err);
    });
  }, [])
  return (
    <MapRender adresser={adresser}/>
  )
}

export default Map;