import React from 'react';
import moment from 'moment';
import { getCookie, setCookie } from 'react-use-cookie';

export function useStickyState(defaultValue, key) {
  const [value, setValue] = React.useState(() => {
    // Object.keys(defaultValue).length && 
    if(!defaultValue.priority) {
      defaultValue.priority = 0;
    }
    const stickyValue = getCookie(key);
    const variable = getCookie(key)?.length > 16
    ? JSON.parse(stickyValue)
    : defaultValue;

    return variable.priority >= defaultValue.priority ? variable : defaultValue;
    // return variable;

  });
  React.useEffect(() => {
    setCookie(key, JSON.stringify(value), {days: value.end ? moment.duration(moment(value.end).diff(moment().startOf('day'))).asDays() : 7});
  }, [key, value]);
  return [value, setValue];
}